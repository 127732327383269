import React from "react"
import {Link} from "gatsby";
import Layout from '../components/layout';
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import SEO from "../components/seo";

import FeaturePararaph from '../components/feature-paragraph'

import ListItem from '../components/list-item';


const PrivacyPolicy = () => (
  <Layout>
    <SEO 
    	title="Privacy Policy"
    	description="Outlining TimeKeeper Privacy Policy"
    	 />
    <Navbar />
    <div className="relative py-16 bg-white overflow-hidden">
		<div className="flex flex-col items-center px-4 sm:px-6 lg:px-8">
		    <h1 className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
		      Privacy Policy
		    </h1>

		</div>
		<div className="mt-12 w-full prose lg:prose-xl text-gray-600 mx-auto">
	    	<p><i>Last Updated: 6th June 2023</i></p>
	    	<p>TimeKeeper is the trading name of Artificialdev Ltd (Company Number: NI647093) whose registered address is located at LoftSpace, 19 Arthur Street, Belfast, Antrim, Northern Ireland, BT1 4GA (collectively referred to as "TimeKeeper”, "we", "us" or "our" in this privacy policy).</p>
		    <p>Timekeeper respects the privacy of its users and is at all times committed to protecting the personal data of those users. This privacy policy sets out how we collect and/or process personal data relating to our HR management subscription services that are available online, on our software application, or on any other Timekeeper services and products relating to this privacy policy including associated support services (“Services”). This privacy policy tells you about your privacy rights and how the law protects you.</p>
		    <h4>Introduction</h4>
		    <p>This privacy policy applies to how we collect, use and share personal data and relates to you (in the context of your status as a TimeKeeper customer and employer organisation), and the individual users of the Services, who may be your employees, servants, agents or contractors (“Users”).</p>
		    <p>It is important that you read this privacy policy so that you are fully aware of how and why we are using your data. It is your responsibility to make this privacy policy available to all Users, and it is your responsibility to ensure that all Users understand the content of this privacy policy. By accessing or using the Services, you agree to the terms set out in this privacy policy, our cookie policy, our terms of business, and other terms and policies of ours that relate to the Services. If you do not agree to this privacy policy, you must leave this website (and associated platforms) and discontinue all use of any of our Services.</p>
		    <p>There are occasions when TimeKeeper acts as a Data Controller, and there are occasions when TimeKeeper acts as a Data Processor. This privacy policy will address both instances.</p>

		    <h4>Changes to the privacy policy</h4>
		    <p>We keep our privacy policy under regular review, and we may change it at any time. Historic versions can be obtained by contacting us.</p>
			
			<h4>Third-party links</h4>
			<p>Our website may use applications that collect personal data. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy policy of every website you visit.</p>

			<h4>The Data We Collect/Process</h4>
			<p>Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).</p>
			<p>We may collect, use, store and transfer different kinds of personal data which we have grouped together as follows:</p>

			<ul>
			    <li><b>Registration Data</b> – personal information that is provided when you are signing up to use our Services, which includes names, email addresses and passwords.</li>
			    <li><b>Financial Data</b> – includes bank account and payment card details, as well as details about payments to and from you and other details of products and services you have purchased from us.</li>
			    <li><b>User Data</b> - information about your employees (Users), to include information relating to terms of employment, remuneration, leave, and position or job function; shift and working pattern information (including the time and date of shifts worked and scheduled); geolocation information relating to the User clocking in and/or clock outing of a shift on a phone or mobile device; and facial recognition data. The minimum information requirement for each User is the employee’s name and email or phone number. Additional information (depending on the service subscribed for) may include job title, external company data, location information and photographic data.</li>
			    <li><b>Technical Data</b> includes IP address, login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform (and other technology on devices used to access the Services), the time spent on webpages, unique device identifiers and diagnostic data, cookies (and related online tracking technologies) to deliver enhanced functionality and to allow us to better understand your interaction and usage.</li>
			    <li><b>Usage Data</b> information about how you use our website, products and services, including records of your communications and interactions with us; information provided by you to our customer service teams as regards the offering of assistance in relation to use of the Services, which might include contact information, written and oral summaries of the issue, documents, images & recordings.</li>
			    <li><b>Marketing and Communications Data</b> includes your preferences in receiving marketing from us and your communication preferences, as well as information relating to customer feedback.</li>
			</ul>
			<p>Save for the very specific use of biometric data (see <b>HOW WE USE PERSONAL DATA</b>) we do not collect <b>Special Categories of Personal Data</b> about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, and genetic). </p>

			<h4>How we Collect Personal Data</h4>
			<p>We use different methods to collect data from and about you including through:</p>

			<ul>
			    <li><b>Direct interactions.</b> By filling in forms or by corresponding with us by post, phone, email or otherwise, and by interacting with the Services we will collect Registration Data and User Data. This includes personal data you provide when you:
			        <ul>
			            <li>apply for our products or services;</li>
			            <li>create an account on our website and associated apps and sub-domains;</li>
			            <li>request marketing to be sent to you;</li>
			            <li>give us feedback or contact us; and</li>
			            <li>add Users to the Services.</li>
			        </ul>
			    </li>
			    <li><b>Automated technologies or interactions.</b> As you (and other Users) interact with our website and associated apps and sub-domains, we will automatically collect Technical Data about your (and User’s) equipment, browsing actions and patterns. We collect this personal data by using cookies, server logs and other similar technologies. Please see our <Link to="/cookie-policy">cookie policy</Link> for further details. We will also collect User Data to allow for the performance and functionality of our website and associated apps and sub-domains.</li>
			    <li><b>Third parties.</b> We will receive personal data (User Data and/or Registration Data) about you from the third party marketplace providers (for example Sage or Xero) where the customer or User has consented to the marketplace provider sending us their personal data.</li>
			</ul>

			<h4>How we Use Personal Data</h4>
			<p>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:</p>
			<ul>
			    <li>Where we need to perform the contract that we are about to enter into or have entered into with you.</li>
			    <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</li>
			    <li>Where we need to comply with a legal obligation.</li>
			</ul>
			<p>See <b><a href="#glossary">GLOSSARY</a></b> to find out more about the types of lawful basis that we will rely on to process your personal data.</p>
			<p><b>Where we process your personal information, or a Users personal information, as a Data Processor, you as our customer, and as the employer organisation, shall be solely responsible for ensuring that there is a legal basis under the GDPR for us processing such personal information on the behalf of you and the Users.</b></p>
			<h4>Purposes for which we will use personal data in our roles as a Data Controller and Data Processor</h4>
			<p>We have set out below, in a table format, a description of all the ways we plan to use personal data, and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are where appropriate.</p>
			<p>We may process personal data for more than one lawful ground depending on the specific purpose for which we are using your data. </p>

			<h4>Data Controller</h4>
			
			<div className="overflow-x-auto">
				<table className="p-4"> 
					<tr className="border p-4">
			            <th>Purpose/Activity</th>
			            <th>Type of data</th>
			            <th>Lawful basis for processing including basis of legitimate interest</th>
			        </tr>
			        <tr>
			            <td className="border p-4">To register you as a new customer</td>
			            <td className="border p-4">(a) Registration </td>
			            <td className="border p-4">Performance of a contract with you</td>
			        </tr>
			        <tr>
			            <td className="border p-4">To process your order including: <br /><br />
						(a) Manage payments, fees and charges <br />
						(b) Collect and recover money owed to us <br />
						and to provide and improve our services to you.
						</td>
			            <td className="border p-4">(a) Registration  <br />
							(b) Financial  <br />
							(c) Marketing and Communications
							</td>
			            <td className="border p-4">(a) Performance of a contract with you  <br />
(b) Necessary for our legitimate interests (to recover debts due to us) <br />
</td>
			        </tr>
			        <tr>
			            <td className="border p-4">To manage our relationship with you which will include: <br /><br />
(a) Notifying you about changes to our terms or privacy policy <br />
(b) Asking you to leave a review or take a survey
</td>
			            <td className="border p-4">(a) Registration <br />
(b) Contact <br />
(c) Usage <br />
(d) Marketing and Communications
 </td>
			            <td className="border p-4">(a) Performance of a contract with you <br />
(b) Necessary to comply with a legal obligation <br />
(c) Necessary for our legitimate interests (to keep our records updated and to study how customers use our products/services)
</td>
			        </tr>
			        <tr>
			            <td className="border p-4">To administer and protect our business and this website (including, data analysis, security  troubleshooting, testing, system maintenance, support, reporting and hosting of data)  </td>
			            <td className="border p-4">(a) Registration <br />
(b) Technical
</td>
			            <td className="border p-4">(a) Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security and to prevent fraud) <br />
(b) Necessary to comply with a legal obligation
</td>
			        </tr>
			        <tr>
			            <td className="border p-4">To use data analytics to improve our website, products/services, marketing, customer relationships and experiences</td>
			            <td className="border p-4">(a) Technical  <br />
(b) Usage 
</td>
			            <td className="border p-4">Necessary for our legitimate interests (to define types of customers for our products and services, to keep our website updated and relevant, to develop our business and to inform our marketing strategy)</td>
			        </tr>
			        <tr>
			            <td className="border p-4">To make suggestions and recommendations to you about goods or services that may be of interest to you</td>
			            <td className="border p-4">(a) Registration <br />
(b) Technical <br />
(c) Usage <br />
(d) Marketing and Communications
</td>
			            <td className="border p-4">Necessary for our legitimate interests (to develop our products/services and grow our business) and Consent</td>
			        </tr>
				</table>
			</div>

			<h4>Data Processor</h4>
			<div className="overflow-x-auto">
				<table className="p-4"> 
					<tr className="border p-4">
			            <th>Purpose/Activity</th>
			            <th>Type of data</th>
			            <th>Lawful basis for processing including basis of legitimate interest</th>
			        </tr>
			        <tr>
			            <td className="border p-4">To enable us to perform various functions as regards the Services including:  <br /><br />
(a)	Calculating the correct  amount of worked hours (including overtime). <br />
(b)	Client human resource management. <br />
(c)	Pay details to calculate estimated pay. <br />
(d)	Clock-in Photograph for facial recognition checks.
</td>
			            <td className="border p-4">(a) User</td>
			            <td className="border p-4">Performance of a contract with you</td>
			        </tr>
			        <tr>
			            <td className="border p-4">To enable us to carry out diagnostic and security functions.</td>
			            <td className="border p-4">(a) User <br />
(b) Technical
 </td>
			            <td className="border p-4">(a) Performance of a contract with you </td>
			        </tr>
			        <tr>
			            <td className="border p-4">To administer the Services in and about ensuring the correct person is clocking in and clocking out, while confirming that person’s location. </td>
			            <td className="border p-4">(a) User <br />
(b) Technical 
</td>
			            <td className="border p-4">(a) Performance of a contract with you </td>
			        </tr>
			    </table>
			</div>

			<h4>Marketing</h4>
			<p>We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising. </p>
			<h4>Promotional offers from us</h4>
			<p>We may use your Registration, Technical, Usage and Marketing & Communications Data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you.  You will receive marketing information from us if you opt in. </p>
			<p>You will receive marketing communications from us if you have requested information from us or purchased services from us and you have not opted out of receiving that marketing.</p>
			<h4>Opting out</h4>
			<p>You can ask us to stop sending you marketing messages at any time by following the opt-out links on any marketing message sent to you or by contacting us at any time. </p>
			<p>Where you opt out of receiving these marketing messages, this will not apply to personal data provided to us as a result of a product/service purchase, product/service experience or other transactions.</p>
			<h4>Cookies</h4>
			<p>You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly. For more information about the cookies we use, please see our <Link to="/cookie-policy">cookie policy</Link>.</p>
			<h4>Change of purpose</h4>
			<p>We will only use your (or the User’s) personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us.</p>
			<p>If we need to use personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.</p>
			<p>Please note that we may process personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.</p>
			<h4>International transfers</h4>
			<p>If you are based within the UK/EU we will only process and/or transfer data outside of the European Economic Area or EEA where it is compliant with the GDPR and the means of transfer provides adequate safeguards in relation to your personal information, including for example:</p>
			<ul>
			    <li>By way of a data transfer agreement with you, incorporating the current standard contractual clauses adopted by the European Commission for the transfer of personal information by controllers in the EEA to controllers and processors in jurisdictions without adequate data protection laws;</li>
			    <li>By way of a data transfer agreement with a third party, incorporating the current standard contractual clauses adopted by the European Commission for the transfer of personal information by controllers in the EEA to controllers and processors in jurisdictions without adequate data protection laws;</li>
			    <li>By transferring your personal information to a country where there has been a finding of adequacy by the European Commission in respect of that country's levels of data protection via its legislation;</li>
			    <li>Where it is necessary for the conclusion or performance of a contract between us and a third party and the transfer is in your interests for the purposes of that contract; or</li>
			    <li>Where you have consented to the data transfer.</li>
			</ul>
			<h4>Disclosures of personal data</h4>
			<p>We may share your personal data with the parties set out in the following link <Link to="/subprocessors">Subprocessors</Link> for the purposes set out in the above tables.  The sharing of personal data with third parties and the use of third party software is at all times done to assist with the performance and functionality of our platform.</p>
			<p>We require all third parties to respect the security of your (and Users) personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your (or Users) personal data for their own purposes and only permit them to process this personal data for specified purposes and in accordance with our instructions.</p>

			<h4>Data Security</h4>
			<p>We have put in place appropriate security measures to prevent personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process personal data on our instructions and they are subject to a duty of confidentiality.</p>
			<p>We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>


			<h4>Data Retention</h4>
			<p><b>How long will you use my personal data for?</b></p>
			<p>We will only retain personal data for as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory or reporting requirements. We may retain personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.</p>
			<p>To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of the personal data, the purposes for which we process the personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements.</p>

			<h4>Your legal rights</h4>
			<p>Under certain circumstances, you have the following rights under data protection laws in relation to your personal data:</p>
			<ul>
			    <li>Request access to your personal data.</li>
			    <li>Request correction of your personal data.</li>
			    <li>Request erasure of your personal data.</li>
			    <li>Object to processing of your personal data.</li>
			    <li>Request restriction of processing your personal data.</li>
			    <li>Request transfer of your personal data.</li>
			    <li>Right to withdraw consent.</li>
			</ul>
			<p>If you wish to exercise any of the rights set out above, please contact us at support@timekeeper.co.uk. </p>

			<h4>No fee usually required</h4>
			<p>You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances.</p>

			<h4>What we may need from you</h4>
			<p>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</p>

			<h4>Time limit to respond</h4>
			<p>We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated. </p>

			<h4 id="glossary">GLOSSARY</h4>
			<p><b>Lawful Basis</b></p>
			<p><b>Legitimate Interest</b> means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us.</p>
			<p><b>Performance of Contract</b> means processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.</p>
			<p><b>Comply with a legal obligation</b> means processing your personal data where it is necessary for compliance with a legal obligation that we are subject to.</p>


			<h4> Contact Details</h4>
			<p>If you have any questions about this privacy policy or our privacy practices, please contact us in the following ways:</p>
			<p>Full name of legal entity: Artificialdev Ltd.</p>
			<p>Email address: support@timekeeper.co.uk</p>
			<p>Postal address: LoftSpace, 19 Arthur Street, Belfast, Antrim, Northern Ireland, BT1 4GA.</p>
			<p>Telephone number: +44 028 9558 2068</p>
			<p>You have the right to make a complaint at any time to the Information Commissioner's Office (ICO), the UK regulator for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance.</p>
		</div>
	</div>
	<Footer/>
  </Layout>
)

export default PrivacyPolicy;
